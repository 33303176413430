import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import useFooter from '../../hooks/useFooter';
import { GatsbyImage } from 'gatsby-plugin-image';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Divider, IconButton } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
import {
    Email,
    Facebook,
    Instagram,
    Phone,
    Twitter,
    YouTube,
} from '@mui/icons-material';

function Copyright() {
    const [year, setYear] = useState(null);

    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);

    return (
        <Typography
            variant="body2"
            align="center"
            color="white"
        >
            {'Copyright © '}
            <Link
                color="inherit"
                href="https://nvls.ca/"
            >
                NV Landscaping Services
            </Link>{' '}
            {year}
            {'.'}
        </Typography>
    );
}

export default function StickyFooter() {
    const footer = useFooter();
    console.log('Footer: ', footer);

    // const { firstName, lastName, email, phoneNumber } = footer?.contactInfo;
    // const logo = footer?.logo?.image;
    // const serviceAreaTitle = footer?.serviceAreas?.title;
    // const areas = footer?.serviceAreas?.areas;
    // const { title, instagram, facebook, twitter, youtube } =
    //     footer?.socialLinks;

    const {
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhoneNumber,
        image,
        serviceAreasTitle,
        areas,
        instagram,
        facebook,
        twitter,
        youtube,
    } = footer;

    const contactName = getContactName(contactFirstName, contactLastName);
    const showLinks = showSocialLinks(instagram, facebook, twitter, youtube);

    return (
        <Box
            sx={{
                py: 5,
                px: 4,
                //mt: 'auto',
                backgroundColor: (theme) => theme.palette.tertiary.main,
                // theme.palette.mode === 'light'
                //     ? theme.palette.grey[200]
                //     : theme.palette.grey[800],
            }}
        >
            <Box
                component="footer"
                width={{ sm: '90%', md: '80%', xxl: '60%' }}
                marginLeft="auto"
                marginRight="auto"
            >
                <Grid2
                    container
                    direction={'column'}
                    spacing={4}
                    color="white"
                >
                    <Grid2 container>
                        <Grid2
                            container
                            xs={12}
                            md={4}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                        >
                            <Grid2>
                                <GatsbyImage
                                    image={image.gatsbyImage}
                                    alt={image.description}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2
                            container
                            xs={12}
                            md={4}
                            justifyContent="flex-start"
                            direction={'column'}
                            spacing={1}
                            alignContent="center"
                        >
                            <Grid2>
                                <Typography variant="h6">
                                    {serviceAreasTitle}
                                </Typography>
                            </Grid2>
                            {areas?.map((area, index) => (
                                <Grid2 key={index}>
                                    <Typography>{area}</Typography>
                                </Grid2>
                            ))}
                        </Grid2>
                        <Grid2
                            container
                            xs={12}
                            md={4}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                            direction={'column'}
                            textAlign={{ xs: 'center', md: 'right' }}
                            spacing={2}
                        >
                            <Grid2>
                                <Typography
                                    component={GatsbyLink}
                                    to="/contact-us"
                                    variant="h6"
                                    sx={{
                                        textDecoration: 'none',
                                        color: 'white',
                                    }}
                                >
                                    Contact Us
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Typography>{contactName}</Typography>
                            </Grid2>
                            <Grid2
                                container
                                alignItems="center"
                                justifyContent={{
                                    xs: 'center',
                                    md: 'flex-end',
                                }}
                            >
                                <IconButton href={'tel:' + contactPhoneNumber}>
                                    <Phone sx={{ color: 'white' }} />
                                    <Typography
                                        color={'white'}
                                        pl="0.5rem"
                                    >
                                        {contactPhoneNumber}
                                    </Typography>
                                </IconButton>
                            </Grid2>
                            <Grid2
                                container
                                alignItems="center"
                                justifyContent={{
                                    xs: 'center',
                                    md: 'flex-end',
                                }}
                            >
                                <IconButton>
                                    <Email sx={{ color: 'white' }} />
                                    <Typography
                                        component={Link}
                                        href={'mailto:' + contactEmail}
                                        target="_blank"
                                        color={'white'}
                                        pl="0.5rem"
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {contactEmail}
                                    </Typography>
                                </IconButton>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2>
                        <Divider
                            //sx={{ mt: { sm: '2rem', sm: '3rem' } }}
                            variant="middle"
                            color="white"
                        />
                    </Grid2>
                    {showLinks && (
                        <Grid2
                            container
                            justifyContent={'center'}
                        >
                            {!!instagram && (
                                <Grid2 xs="3">
                                    <IconButton
                                        href={instagram}
                                        target="_blank"
                                        aria-label="Instagram"
                                    >
                                        <Instagram sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid2>
                            )}
                            {!!facebook && (
                                <Grid2 xs="3">
                                    <IconButton
                                        href={facebook}
                                        target="_blank"
                                        aria-label="Facebook"
                                    >
                                        <Facebook sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid2>
                            )}
                            {!!twitter && (
                                <Grid2 xs="3">
                                    <IconButton
                                        href={twitter}
                                        target="_blank"
                                        aria-label="Twitter"
                                    >
                                        <Twitter sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid2>
                            )}
                            {!!youtube && (
                                <Grid2 xs="3">
                                    <IconButton
                                        href={youtube}
                                        target="_blank"
                                        aria-label="Youtube"
                                    >
                                        <YouTube sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid2>
                            )}
                        </Grid2>
                    )}
                    <Grid2>
                        <Container maxWidth="sm">
                            <Typography
                                variant="body1"
                                align="center"
                            >
                                Website created by: Juan Carlos Pinillos
                            </Typography>
                            <Copyright />
                        </Container>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
}

/**
 * Gets the contacts name by building it from the first and last name if available
 * @param {string} firstName the contact first name
 * @param {string} lastName the contact last name
 * @returns either the built contact name by using the first and last name when available or false if neither is set
 */
const getContactName = (firstName, lastName) => {
    let contactName;
    if (firstName && lastName) {
        contactName = firstName + ' ' + lastName;
    } else if (firstName) {
        contactName = firstName;
    } else if (lastName) {
        contactName = lastName;
    } else {
        contactName = false;
    }
    return contactName;
};

/**
 * Checks if the Social Links need to be shown
 * @param {string} instagram an instagram account url if set
 * @param {string} facebook a facebook account url if set
 * @param {string} twitter a twitter account url if set
 * @param {string} youtube a youtube account url if set
 * @returns true if any of the arguments are set and false if all of them are null, empty, or undefined
 */
const showSocialLinks = (instagram, facebook, twitter, youtube) => {
    if (instagram || facebook || twitter || youtube) {
        return true;
    } else {
        return false;
    }
};
