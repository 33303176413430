exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contentful-homepage-url-js": () => import("./../../../src/pages/{contentfulHomepage.url}.js" /* webpackChunkName: "component---src-pages-contentful-homepage-url-js" */),
  "component---src-templates-form-page-js": () => import("./../../../src/templates/FormPage.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-navigation-page-js": () => import("./../../../src/templates/NavigationPage.js" /* webpackChunkName: "component---src-templates-navigation-page-js" */),
  "component---src-templates-reviews-page-js": () => import("./../../../src/templates/ReviewsPage.js" /* webpackChunkName: "component---src-templates-reviews-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

