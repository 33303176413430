import { graphql, useStaticQuery } from 'gatsby';

const useMainNavigation = () => {
    const { contentfulMainNavigation } = useStaticQuery(graphql`
        query MainNavigationQuery {
            contentfulMainNavigation {
                label
                navigationItems {
                    label
                    mainPage {
                        title
                        slug
                    }
                    pages {
                        title
                        slug
                    }
                }
                logo {
                    image {
                        title
                        description
                        url
                        gatsbyImage(width: 30)
                    }
                }
                callToActionButton {
                    label
                    page {
                        slug
                    }
                }
            }
        }
    `);
    return contentfulMainNavigation;
};

export default useMainNavigation;
