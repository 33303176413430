import { graphql, useStaticQuery } from 'gatsby';

const useFooter = () => {
    const { contentfulFooter } = useStaticQuery(graphql`
        query ContentfulFooterQuery {
            contentfulFooter {
                title
                contactTitle
                contactFirstName
                contactLastName
                contactPhoneNumber
                contactEmail
                image {
                    title
                    description
                    url
                    gatsbyImage(width: 300)
                }
                instagram
                facebook
                serviceAreasTitle
                areas
            }
        }
    `);
    return contentfulFooter;
};

export default useFooter;
