import {
    Box,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import { Link } from 'gatsby';
import React, { Fragment } from 'react';

export const DrawerContent = ({ handleDrawerToggle, navItems, ctaButton }) => {
    const theme = useTheme();
    return (
        <Box
            onClick={handleDrawerToggle}
            sx={{ textAlign: 'center' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: theme.palette.primary.main,
                    justifyContent: 'center',
                }}
            >
                <Typography
                    //component={Link}
                    //to={'/'}
                    variant="h6"
                    sx={{
                        lineHeight: theme.spacing(6),
                        color: theme.palette.primary.contrast,
                        textDecoration: 'none',
                    }}
                >
                    NV Landscaping Services
                </Typography>
            </Box>

            <Divider />
            <List>
                {navItems.map((item, index) => {
                    const { label, mainPage, pages } = item;
                    const labelSlug = label.toLowerCase().replace(/\s+/g, '-');
                    return (
                        <Fragment key={index}>
                            <ListItem disablePadding>
                                {mainPage ? (
                                    <ListItemButton
                                        sx={{ textAlign: 'left' }}
                                        component={Link}
                                        to={'/' + labelSlug}
                                    >
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                )}
                            </ListItem>
                            {pages && (
                                <Collapse
                                    in={true}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                    >
                                        {pages.map((page, index) => (
                                            <ListItemButton
                                                key={index}
                                                sx={{ pl: 4 }}
                                                component={Link}
                                                to={
                                                    '/' +
                                                    labelSlug +
                                                    '/' +
                                                    page.slug
                                                }
                                            >
                                                <ListItemText
                                                    primary={page.title}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </Fragment>
                    );
                })}
                <Fragment>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to={'/' + ctaButton.page.slug}
                            sx={{
                                textAlign: 'left',
                                color: theme.palette.secondary.contrast,
                                background: theme.palette.secondary.main,
                            }}
                        >
                            <ListItemText primary={ctaButton.label} />
                        </ListItemButton>
                    </ListItem>
                </Fragment>
            </List>
        </Box>
    );
};
