import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import useMainNavigation from '../../hooks/useMainNavigation';
import { Drawer } from './Drawer';
import { MainNavigationItems } from './MainNavigationItems';

export const MainNavigation = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const theme = useTheme();

    const mainNavigation = useMainNavigation();
    const navItems = mainNavigation?.navigationItems;
    const ctaButton = mainNavigation?.callToActionButton;
    console.log('NAV ITEMS: ', navItems);

    return (
        <Box sx={{ display: 'flex', height: 64 }}>
            <AppBar component="nav">
                <Toolbar sx={{ minHeight: 64 }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component={Link}
                        to={'/'}
                        sx={{
                            display: { md: 'block' },
                            textDecoration: 'none',
                            color: theme.palette.primary.contrast,
                        }}
                    >
                        NVLS
                    </Typography>
                    <Box
                        component={'div'}
                        sx={{ flexGrow: 1 }}
                    />
                    <MainNavigationItems navItems={navItems} />
                    {!!ctaButton && (
                        <Button
                            variant="contained"
                            color={'secondary'}
                            component={Link}
                            to={'/' + ctaButton.page.slug}
                            sx={{ ml: 3, display: { xs: 'none', md: 'block' } }}
                        >
                            {ctaButton.label}
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={handleDrawerToggle}
                    navItems={navItems}
                    ctaButton={ctaButton}
                />
            </Box>
        </Box>
    );
};
