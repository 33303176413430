import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
    Box,
    createTheme,
    CssBaseline,
    GlobalStyles,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material';
import React from 'react';
import StickyFooter from '../components/Footer/StickyFooter';
import { MainNavigation } from '../components/Navigation';

export const RootElement = ({ children }) => {
    let theme = createTheme({
        palette: {
            primary: {
                main: '#fbbe00',
                light: '#FFFF6B',
                dark: '#C6A700',
                contrast: '#000000',
                softContrast: 'rgba(0,0,0,0.5)',
            },
            secondaryAlt: {
                main: '#022204',
            },
            secondary: {
                main: '#005618',
                light: '#619648',
                dark: '#003D00',
                contrast: '#FFFFFF',
                softContrast: 'rgba(255, 255, 255, 0.5)',
            },
            tertiaryAlt: {
                main: '#1c1c1c',
                light: '#2c2c2c',
                dark: '#000',
            },
            tertiary: {
                main: '#212121',
                light: '#484848',
                dark: '#000000',
                contrast: '#FFFFFF',
                softContrast: 'rgba(255, 255, 255, 0.5)',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                ssm: 430,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1921,
            },
        },
        // components: {
        //     MuiCssBaseline: {
        //         styleOverrides: {
        //             body: {
        //                 overflowX: 'hidden',
        //                 scrollbarWidth: 'thin',
        //                 scrollbarColor: (theme) =>
        //                     `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
        //                 '&::-webkit-scrollbar': {
        //                     width: '0.5em',
        //                 },
        //                 '&::-webkit-scrollbar-track': {
        //                     backgroundColor: (theme) => theme.palette.grey[200],
        //                 },
        //                 '&::-webkit-scrollbar-thumb': {
        //                     backgroundColor: (theme) =>
        //                         theme.palette.primary.main,
        //                 },
        //             },
        //         },
        //     },
        // },
        // components: {
        //     MuiCssBaseline: {
        //         styleOverrides: {
        //             html: {
        //                 overflowX: 'hidden',
        //             },
        //             body: {
        //                 overflowX: 'hidden',
        //             },
        //         },
        //     },
        // },
    });

    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    body: {
                        '&.MuiPopover-body': {
                            paddingRight: 0,
                        },
                    },
                }}
            />
            <Box
                sx={{
                    minHeight: `calc(100vh - 108px)`,
                }}
            >
                <MainNavigation />
                <Box component={'main'}>{children}</Box>
            </Box>
            <StickyFooter />
        </ThemeProvider>
    );
};
