import { Drawer as MuiDrawer } from '@mui/material';
import React from 'react';
import { DrawerContent } from './DrawerContent';

const drawerWidth = 260;

export const Drawer = ({
    mobileOpen,
    handleDrawerToggle,
    navItems,
    ctaButton,
}) => {
    return (
        <MuiDrawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                },
            }}
        >
            <DrawerContent
                handleDrawerToggle={handleDrawerToggle}
                navItems={navItems}
                ctaButton={ctaButton}
            />
        </MuiDrawer>
    );
};
