import { Box, Button, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from 'gatsby';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React, { Fragment } from 'react';

export const MainNavigationItems = ({ navItems, ctaButton }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {navItems.map((item, index) => {
                const { label, pages } = item;
                const labelSlug = label.toLowerCase().replace(/\s+/g, '-');
                return (
                    <Fragment key={index}>
                        {pages ? (
                            <Box display="inherit">
                                <PopupState
                                    variant="popover"
                                    popupId={label + '-popover'}
                                >
                                    {(popupState) => (
                                        <div>
                                            <Button
                                                sx={{
                                                    color: 'black',
                                                }}
                                                size="large"
                                                component={Link}
                                                to={'/' + labelSlug}
                                                {...bindHover(popupState)}
                                            >
                                                {item.label}
                                            </Button>
                                            <HoverPopover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Grid2
                                                    container
                                                    xs={12}
                                                    direction="column"
                                                >
                                                    {pages.map(
                                                        (page, index) => {
                                                            const {
                                                                title,
                                                                slug,
                                                            } = page;
                                                            return (
                                                                <Grid2
                                                                    key={index}
                                                                >
                                                                    <Typography
                                                                        component={
                                                                            Link
                                                                        }
                                                                        to={
                                                                            '/' +
                                                                            labelSlug +
                                                                            '/' +
                                                                            slug
                                                                        }
                                                                        //selected={false}
                                                                        sx={{
                                                                            p: 1,
                                                                            flexGrow: 1,
                                                                            display:
                                                                                {
                                                                                    sm: 'block',
                                                                                },
                                                                            textDecoration:
                                                                                'none',
                                                                            color: '#000',
                                                                            backgroundColor:
                                                                                theme
                                                                                    .palette
                                                                                    .primary
                                                                                    .main,
                                                                        }}
                                                                        //onClick={handleMenuClose}
                                                                    >
                                                                        {title}
                                                                    </Typography>
                                                                </Grid2>
                                                            );
                                                        }
                                                    )}
                                                </Grid2>
                                            </HoverPopover>
                                        </div>
                                    )}
                                </PopupState>
                            </Box>
                        ) : (
                            <Box>
                                <Button
                                    sx={{
                                        color: 'black',
                                    }}
                                    size="large"
                                    component={Link}
                                    to={'/' + labelSlug}
                                >
                                    {item.label}
                                </Button>
                            </Box>
                        )}
                    </Fragment>
                );
            })}
        </Box>
    );
};
